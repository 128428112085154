<template>
  <CommonsModalsModalBody :svg="svg" height="auto">
    <template #title> Ajouter un article </template>

    <template #help>
      {{ help }}
    </template>

    <template #content>
      <uds-switch
        style="justify-content: center"
        label="Importer un fichier CSV"
        :value="isImportCsv"
        :color="primaryColor"
        @change="isImportCsv = !isImportCsv"
      />

      <template v-if="isImportCsv">
        <div class="np-import">
          <div>
            <CommonsDropzoneContainer
              :extensions="['text/csv']"
              :max-files="1"
              :max-size="2"
              :show-drop-box-button="true"
              @updated="files = $event"
            />
          </div>
          <div>
            <div class="description-import">
              Importer vos articles au format CSV (<span class="csv-model"
                ><a :href="sampleCsvLink">Télécharger le modèle</a></span
              >).
              <br >
              Il est impératif de ne pas supprimer ou modifier l'ordre des colonnes (1 500 lignes
              maximum).
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="np-form">
          <div class="np-row">
            <uds-input-string
              label="Dénomination"
              :value="form.name"
              :error="err.name"
              is-required
              @change="form.name = $event || null"
            />

            <uds-input-select-custom label="Type" is-required>
              <template #inputContent>
                <span v-if="typeText" class="truncate-text">{{ typeText }}</span>
                <span v-else class="placeholder">Choisir le type</span>
              </template>
              <template #dropdownContent="{ hide }">
                <div class="uds-dropdown-list">
                  <div
                    v-for="(item, index) in ProductTypeList"
                    :key="index"
                    class="uds-dropdown-line"
                    @click="(form.type = item.id), hide()"
                  >
                    <span class="uds-dropdown-item">
                      {{ item.name }}
                    </span>
                  </div>
                </div>
              </template>
            </uds-input-select-custom>

            <uds-input-amount
              style="max-width: 120px"
              label="Prix unitaire HT"
              :value="devideBy100(form.priceTaxExcluded ?? 0)"
              :min="0"
              :error="err.priceTaxExcluded"
              @change="form.priceTaxExcluded = multiplyBy100($event)"
            />
          </div>
          <div class="np-row">
            <uds-input-select-custom label="Unité" is-required>
              <template #inputContent>
                <span v-if="unityText" class="truncate-text">{{ unityText }}</span>
                <span v-else class="placeholder">Choisir l'unité</span>
              </template>
              <template #dropdownContent="{ hide }">
                <div class="uds-dropdown-list">
                  <div
                    v-for="(item, index) in unitiesList"
                    :key="index"
                    class="uds-dropdown-line"
                    @click="(form.unit = item.id), hide()"
                  >
                    <span class="uds-dropdown-item">
                      {{ item.name }}
                    </span>
                  </div>
                </div>
              </template>
            </uds-input-select-custom>
            <uds-input-select-custom label="Taux de TVA" is-required>
              <template #inputContent>
                <span v-if="vatText" class="truncate-text">{{ vatText }}</span>
                <span v-else class="placeholder">Choisir la tva</span>
              </template>
              <template #dropdownContent="{ hide }">
                <div class="uds-dropdown-list">
                  <div
                    v-for="(item, index) in filteredVats"
                    :key="index"
                    class="uds-dropdown-line"
                    @click="(form.idVat = item.id), hide()"
                  >
                    <span class="uds-dropdown-item">
                      {{ item.name }}
                    </span>
                  </div>
                </div>
              </template>
            </uds-input-select-custom>

            <uds-input-string
              label="Référence"
              :value="form.codeProduct"
              @change="form.codeProduct = $event || null"
            />
          </div>
          <div v-if="isMarginVat" class="np-row">
            <uds-input-select-popper
              label="TVA sur marge"
              placeholder="Choisir une TVA"
              :value="form.marginVatType"
              :items="vatMarginCategyList"
              item-value="id"
              item-text="label"
              @select="form.marginVatType = $event || null"
            />
            <uds-input-amount
              label="Prix d'achat U."
              :value="devideBy100(form.amountOfPurchase ?? 0)"
              :error="
                form.amountOfPurchase && form.amountOfPurchase > (form.priceTaxExcluded ?? 0)
                  ? 'Attention! Le prix d\'achat est supérieur au prix de vente.'
                  : null
              "
              @change="form.amountOfPurchase = multiplyBy100($event)"
            />
          </div>
          <div class="np-row">
            <uds-input-textarea
              :value="form.comment"
              label="Commentaire"
              @change="form.comment = $event.target?.value"
            />
          </div>
        </div>
      </template>
    </template>

    <template #footer>
      <template v-if="isImportCsv">
        <uds-main-button
          size="small"
          :loading="isLoading"
          :disabled="!isCsvFormValid"
          @click="importCsvArticle"
          >Enregistrer</uds-main-button
        >
      </template>
      <template v-else>
        <uds-main-button size="small" :loading="isLoading" :disabled="!isValid" @click="addArticle"
          >Enregistrer</uds-main-button
        >
      </template>
    </template>
  </CommonsModalsModalBody>
</template>

<script setup lang="ts">
import type {
  VatType,
  CreateProduct} from "@silexpert/core";
import {
  Unit,
  ProductType,
  ProductTypeList,
  UnitList,
  Vat,
  VatMarginCategyList,
} from "@silexpert/core";
type ArticleForm = {
  [key in keyof CreateProduct]: CreateProduct[key] | null;
};

const emit = defineEmits(["close"]);

const props = defineProps<{
  help: string;
  svg?: string;
  forceImportCsv?: boolean;
}>();

const isLoading = ref(false);
const isImportCsv = ref(false);

const productStore = useProductStore();

onMounted(() => {
  isImportCsv.value = props.forceImportCsv;
});

const form = ref<ArticleForm>({
  name: null,
  type: ProductType.PRODUCT.id,
  priceTaxExcluded: 0,
  unit: Unit.ITEM.id,
  idVat: null,
  codeProduct: null,
  comment: null,
  isFree: false,
  amountOfPurchase: 0,
  marginVatType: null,
});

const files = ref<File[]>([]);
const isCsvFormValid = computed(() => files.value.length === 1);

const sampleCsvLink =
  "https://resources-clementine.s3.eu-west-3.amazonaws.com/sampleCSV/importProduits_v2.csv";

const primaryColor = computed(() => {
  return getPrimaryColors().primary500;
});

// article form

const typeText = computed(() => {
  const matchingProductTypes = ProductTypeList.find(
    (productType) => productType.id === form.value.type,
  );
  return matchingProductTypes?.name ?? "Choisir le type";
});

const unityText = computed(() => {
  const matchingUnity = UnitList.find((unity) => unity.id === form.value.unit);
  return matchingUnity?.name ?? "Choisir l'unité";
});

const unitiesList = computed(() => {
  return Object.values(Unit);
});

// VAT
const utils = useAnnotationUtilsComposable();
const serverInfoStore = useServerInfoStore();

const vats = serverInfoStore.getVats ?? [];
const filteredVats = utils.getVatsForAnnotating({
  intracom: false,
  margin: true,
  manual: false,
});

const vatText = computed<string | null>(() => {
  const matchingVat = vats.find((vat: VatType) => vat.idTva === form.value.idVat);
  return matchingVat?.name ?? "Choisir la TVA";
});

const isMarginVat = computed<boolean>(() => {
  return form.value.idVat === Vat.MARGE.idTva;
});

const vatMarginCategyList = computed<typeof VatMarginCategyList>(() => {
  return VatMarginCategyList;
});

// Actions
const err = computed<{ [key: string]: string | null }>(() => {
  const { name, type, unit, priceTaxExcluded, isFree, idVat } = form.value;
  return {
    name: !isDefined(name) || name.length === 0 ? "Le champ est requis." : null,
    type: !isDefined(type) ? "Le champ est requis." : null,
    unit: !isDefined(unit) ? "Le champ est requis." : null,
    priceTaxExcluded: !isDefined(priceTaxExcluded) && !isFree ? "Le champ est requis." : null,
    idVat: !isDefined(idVat) ? "Le champ est requis." : null,
  };
});

const isValid = computed(() => {
  for (const property in err.value) {
    const value = err.value[property];
    if (isDefined(value)) {
      return false;
    }
  }
  return true;
});

async function addArticle() {
  isLoading.value = true;

  const {
    name,
    type,
    priceTaxExcluded,
    unit,
    idVat,
    codeProduct,
    comment,
    isFree,
    amountOfPurchase,
    marginVatType,
  } = form.value;

  // remove all null values
  // we can assume that the type is respected if the validation passes
  const payload: CreateProduct = {
    ...(isDefined(name) ? { name } : {}),
    ...(isDefined(type) ? { type } : {}),
    ...(isDefined(priceTaxExcluded) ? { priceTaxExcluded } : {}),
    ...(isDefined(unit) ? { unit } : {}),
    ...(isDefined(idVat) ? { idVat } : {}),
    ...(isDefined(codeProduct) ? { codeProduct } : {}),
    ...(isDefined(comment) ? { comment } : {}),
    ...(isDefined(isFree) ? { isFree } : {}),
    ...(isMarginVat.value ? { amountOfPurchase, marginVatType } : {}),
  } as CreateProduct;

  await $silex()
    .product.create(payload)
    .then(async () => {
      $notifier().open({ type: "success", content: "Produit ajouté avec succès." });
      await productStore.fetchAllProducts();
    })
    .catch((error: any) => {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    });

  emit("close");
}

async function importCsvArticle() {
  isLoading.value = true;

  const file = files.value[0] as File;
  const formData = new FormData();
  formData.append("fileProducts", file);
  await $silex()
    .product.import(file)
    .then(() => {
      $notifier().open({ type: "success", content: "Importation d'articles réussie avec succès" });
    })
    .catch((error: any) => {
      $notifier().open({ type: "error", content: apiErrorToString(error) });
    });

  isLoading.value = false;
  emit("close");
}
</script>

<style lang="scss" scoped>
/// CREATION FORM
.np-form {
  margin-top: $uds-spacing-1;
  font-style: normal;
  flex-grow: 1;

  .np-row {
    display: flex;
    &:not(:first-child) {
      margin-top: 15px;
    }

    > * {
      flex-basis: 100%;
      flex-grow: 1;
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}

/// CSV IMPORT
.np-import {
  margin-top: $uds-spacing-1;
}

.description-import {
  text-align: center;
  margin: auto;
  line-height: 20px;
  font-size: 14px;
  max-width: 475px;
}

.csv-model {
  color: $uds-primary-500;
  cursor: pointer;
  text-decoration: none;
}
</style>
